import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import auth from '../reducers/auth';
import url from '../reducers/urlSlice';

// --------------------------------- storage ---------------------------
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import projects from 'src/reducers/projects';
import jobDetailModalSlice from 'src/reducers/jobDetailModalSlice';

const createNoopStorage = () => ({
  getItem(_key) {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem(_key) {
    return Promise.resolve();
  },
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();
// ----------------------------------------------------------------------------

const reducers = combineReducers({
  auth,
  url,
  projects: projects,
  jobDetailModalSlice,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
