// const isObject = (suspect) => typeof suspect == 'object';
// const isNumber = (suspect) => !isNaN(suspect);

// const getType = (suspect) => {
//     if (isObject(suspect)) return 'object';
//     if (isNumber(suspect)) return 'number';
//     return 'string';
// };


// class BaseStorage {
//     name = ''
//     type = ''

//     constructor(name, type) {
//         this.name = name
//         this.type = type
//     }

//     getInstance() {
//         if (this.type == 'local') {
//             return localStorage.getItem(this.name)
//         }
//     }

//     remove(name) {
//         return localStorage.removeItem(this.name)
//     }
// }

const l_get = (key) => {
    const data = localStorage.getItem(key);
    try {
        return JSON.parse(data);
    } catch (e) {
        return data;
    }
};

const l_set = (key, value) => {
    localStorage.setItem(key, value);
};

const l_remove = (key) => {
    localStorage.removeItem(key);
};

// ------------ session ------------
const s_get = (key) => {
    const data = sessionStorage.getItem(key);
    try {
        return JSON.parse(data);
    } catch (e) {
        return data;
    }
};

const s_set = (key, value) => {
    sessionStorage.setItem(key, value);
};

const s_remove = (key) => {
    sessionStorage.removeItem(key);
};

export const base = {
    local: {
        get: l_get,
        set: l_set,
        remove: l_remove,
    },
    sessiom: {
        get: s_get,
        set: s_set,
        remove: s_remove,
    }
};
