import { base } from "./base";

const LocalConstFirebase = {
  ID: 'id',
  PHOTO_URL: 'photoUrl',
  NICKNAME: 'nickname',
  ABOUT_ME: 'aboutMe',
  NODE_MESSAGES: 'messages',
  NODE_USERS: 'users',
  UPLOAD_CHANGED: 'state_changed',
  DOC_ADDED: 'added',
  PREFIX_IMAGE: 'image/',

  // DOCUMENT TYPE
  DOC_TYPE_DOCUMENT: 'doc',
  DOC_TYPE_IMAGE: 1,

  FIREBASE: 'firebase'
};


export const StoreConst = {
  USER: 'USER',
  CLIENT: 'CLIENT',
  FREELANCER: 'FREELANCER',
  ...LocalConstFirebase
};
// export const store = { ...base.local };

export const LocalStore = { ...base.local, ...StoreConst, Const: StoreConst }

