import { createSlice } from '@reduxjs/toolkit';


const urlSlice = createSlice({
  name: 'url',
  initialState: {
    urlData:null,
    client: null,
    freelancer: null,
    project: null,
    proposal: null
  },
  reducers: {
    setProposal(state, action) {
      state.proposal = action.payload;
    },
    setProject(state, action) {
      state.project = action.payload;
    },
  },
});

export const { setProject ,setProposal } = urlSlice.actions;

export default urlSlice.reducer;
