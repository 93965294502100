import { createSlice } from '@reduxjs/toolkit';
// import { parseJSON } from 'date-fns';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authData: null,
    isLogin: false,
  },
  reducers: {
    login(state, action) {
      state.authData = action.payload;
      state.isLogin = true;
    },
    logout(state) {
      state.authData = null;
      state.isLogin = false;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
