import { useState, useEffect } from 'react';
// import { defaultSettings } from 'src/config';

// ----------------------------------------------------------------------

export const LSConstKey = {
  firebase: 'firebase'
}
export default function useLocalStorage(key, defaultValue) {
  // const [value, setValue] = useState(defaultValue);
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue)
      );
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    const storedValue = window.localStorage.getItem(key);

    if (storedValue !== null & storedValue !== 'undefined') {
      try {
        const v = JSON.parse(storedValue)
        setValue(v);
      } catch {
        setValue(null);
      }

    }
  }, [key]);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

