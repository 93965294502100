import { createSlice } from '@reduxjs/toolkit';
// import { parseJSON } from 'date-fns';

const initialState = {
  isModalVisible: false,
  jobID: '',
  userRole: '',
};

const jobDetailModalSlice = createSlice({
  name: 'jobDetailModalSlice',
  initialState: initialState,
  reducers: {
    setUserRole(state, action) {
      state.userRole = action.payload;
    },
    setModalVisibility(state, action) {
      state.isModalVisible = action.payload;
    },
  },
});

export const { setUserRole, setModalVisibility } = jobDetailModalSlice.actions;

export default jobDetailModalSlice.reducer;
