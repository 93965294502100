import PropTypes from 'prop-types';
import Head from 'next/head';
import { forwardRef } from 'react';
import Script from 'next/script'

// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, meta, title, ...other }, ref) => (
  <>
    <Head>
      <title>{`${title} | ZONE-UI`}</title>
      {meta}
    </Head>
    <Script src="https://checkout.razorpay.com/v1/checkout.js" />

    <Box ref={ref} {...other}>  
      {children}
    </Box>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default Page;
