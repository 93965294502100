import { createSlice } from '@reduxjs/toolkit';

const initialValues = {
  result: [],
  status: false,
};

const projects = createSlice({
  name: 'projects',
  initialState: initialValues,

  reducers: {
    setProject(state, action) {
      // console.log('FROM REDUCER');
      // console.log(action.payload);
      state.result = action.payload.result;
      state.status = action.payload.status;
    },
  },
});

export const { setProject } = projects.actions;

export default projects.reducer;
